import './App.css';
import { useState, useEffect } from 'react'
import { supabase } from './client'


function App() {
  const [recipes, setRecipes] = useState([])
  const [recipe, setRecipe] = useState({ recipe_name: "", content: ""})
  const { recipe_name, content } = recipe
  useEffect(() => {
    fetchRecipes()
  }, [])
  async function fetchRecipes() {
    const {data} = await supabase
      .from('recipes')
      .select()
    setRecipes(data)
    console.log(data)
  }
  async function createRecipe() {
    if (recipe_name === '') {
      alert("No Name")
    } else {
      if (content === '') {
        alert("No Content")
      } else {
          await supabase
      .from('recipes')
      .insert([
        { recipe_name, content }
      ])
      .single()
    setRecipe({ recipe_name: "", content: ""})
    fetchRecipes()
        }   
      }
  }
  return (
    <div className='App'>
      <h1>Recipe Record</h1>
      <label>Recipe Name</label><br/>
      <input
      className='name'
      value={recipe_name}
      onChange={e => setRecipe({ ...recipe, recipe_name: e.target.value})}
      />    
      <br/>
      <br/>
      <label>Recipe Ingredients</label><br/>
      <textarea 
      className='content'
      value={content}
      onChange={e => setRecipe({ ...recipe, content: e.target.value})}
      />
      <br/>
      <button className='add' onClick={createRecipe}>Add Recipe</button>
      <div className="list">
      {
        recipes.map(recipe => (
          <div className='list' key={recipe.id}>
              <h3 className='title-list'>{recipe.recipe_name}</h3>
              <p className='content-list'>{recipe.content}</p>
          </div>
        ))
      }
      </div>
      <p>Roboteam.In 2022</p>
    </div>
  );
}

export default App;
